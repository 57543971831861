import React, { Component } from 'react';
import { Switch, Route, withRouter} from 'react-router-dom';
import firebase from 'firebase/app';
import { firebaseApp } from './base';
import { apiRequest as apiResponse, queryRequest as queryResponse, getFirebaseData  } from './utils/apiRequest'
import Loginout from './components/Loginout/Loginout';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Modal from './components/Modal/Modal';
import MovieSearch from './components/MovieSearch/MovieSearch';
import MovieList  from './components/MovieList/MovieList';
import NotFound from './components/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import './App.css';

class App extends Component {
  state = {
    isSignedIn: false,
    isScrolled: false,
    mobileNavDisplayed: false,  
    modalDisplayed: false,
    selectedMovie: '',
    movieHomeCover: '',
    querySearch: '',
    multiSearch: [],
    moviesGenres: [],
    userList: {
      movies: [],
      owner: '',
    },
    movies: {
      topTrending: [],
      topRated: [],
      actionGenre: [],
      comedyGenre: [],
      misteryGenre: [],
      romanceGenre: [],
      animationGenre: [],
      documentaryGenre: [],
    },
  };

  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    const oldstate = { ...this.state };
    const state = await apiResponse(oldstate);

    this.setState(state);

    firebaseApp.auth().onAuthStateChanged(user => {
      if (user) {
        this.handleAuth(user);
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userList.movies !== this.state.userList.movies && this.state.isSignedIn) {
      firebaseApp.database().ref('/' + this.state.userList.owner).set(this.state.userList);
    }
  }

  handleScroll = (event) => {  
    if (window.scrollY === 0 && this.state.isScrolled === true) {
      this.setState({isScrolled: false});
    } 
    else if (window.scrollY !== 0 && this.state.isScrolled === false) {
      this.setState({isScrolled: true});
    }
  }

  handleShowRemoveElement = (oldState) => {
    const stateProperty = Object.keys(oldState)[0];
    this.setState({ [stateProperty]: !this.state[stateProperty]});
  }

  handleSelectedMovieModal = (movie) => {
    const state = { ...this.state };
    state.selectedMovie = movie;
    this.setState(state);
  }

  handleInputChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
    if (this.state.querySearch && this.state.querySearch.length > 1) {
      const multiSearch = await queryResponse(this.state.querySearch);
      this.setState({ multiSearch }); 
    } else {
      this.setState({ multiSearch: [] }); 
    }
  }

  handleAddRemoveMovie = async (movie) => {
    const sameMovie = this.state.userList.movies.some(m => m.id === movie.id);
    
    let userList = { ...this.state.userList };

    if (sameMovie) {
      userList.movies = this.state.userList.movies.filter(m => m.id !== movie.id);
    } else if (!sameMovie) {
      userList.movies = [ movie, ...this.state.userList.movies ];
    }

    this.setState({ userList });
  }

  handleAuthenticate = (provider) => {
    const authProvider = new firebase.auth[`${provider}AuthProvider`]();
    firebaseApp.auth().signInWithPopup(authProvider).then(this.handleAuth) 
  }
  
  handleAuth = async (authData) => {
    if (this.props.location.pathname === '/login') {
      this.props.history.replace('/');
    }
    
    const userId = authData.uid || authData.user.uid;

    const userList = await getFirebaseData(this.state.userList, userId);

    this.setState({ userList, userId, isSignedIn: true });
  }

  handleLogout = async () => {
    console.log('logout');
    await firebaseApp.auth().signOut();

    const userList = { ...this.state.userList };
    userList.movies = [];
    userList.owner = '';

    this.setState({ userList, isSignedIn: false });
  }

  handleMainSearchRoute = (props) => {
    if (this.props.location.pathname === '/') {
      if (this.state.querySearch.length > 2) {
        return ( 
          <MovieSearch 
            imgSize={{ width: '25rem', height: '35rem' }} 
            selectedMovieModal={this.handleSelectedMovieModal}
            showRemoveElement={this.handleShowRemoveElement}
            modalDisplayed={this.state.modalDisplayed}
            movies={this.state.multiSearch} 
          />
        );
      } 
      return (
        <Home 
          router={props}
          movies={this.state.movies} 
          isSignedIn={this.state.isSignedIn}
          showRemoveElement={this.handleShowRemoveElement}
          selectedMovieModal={this.handleSelectedMovieModal}
          movieHomeCover={this.state.movieHomeCover}
          addRemoveMovie={this.handleAddRemoveMovie}
          movieList={this.state.userList.movies}
        />
      );
    } else if (this.props.location.pathname === '/my-list') {
      if (this.state.querySearch.length > 2) {
        return ( 
          <MovieSearch 
            imgSize={{ width: '25rem', height: '35rem' }} 
            selectedMovieModal={this.handleSelectedMovieModal}
            showRemoveElement={this.handleShowRemoveElement}
            modalDisplayed={this.state.modalDisplayed}
            movies={this.state.multiSearch} 
          />
        );
      } 
      return (
        <MovieList 
          title="My list"
          imgSize={{ width: '25rem', height: '35rem' }} 
          selectedMovieModal={this.handleSelectedMovieModal}
          showRemoveElement={this.handleShowRemoveElement}
          modalDisplayed={this.state.modalDisplayed}
          movies={this.state.userList.movies} 
        />
      );
    }
  }

  render() { 
    const backgroundColor = this.props.location.pathname !== '/login' && this.props.location.pathname !== '/logout' ? 
    'App-black-background' : '';

    const renderHeader = this.props.location.pathname !== '/login' && this.props.location.pathname !== '/logout';

    return ( 
      <React.Fragment>
      {
        renderHeader ?
        <Header 
          inputChange={this.handleInputChange}
          showRemoveElement={this.handleShowRemoveElement}
          mobileNavDisplayed={this.state.mobileNavDisplayed} 
          isScrolled={this.state.isScrolled}
          querySearch={this.state.querySearch}
          moviesGenres={this.state.moviesGenres}
          isSignedIn={this.state.isSignedIn}
          logout={this.handleLogout}
        />
          :
        null
      }
        <main className={backgroundColor}>
          <Switch>
            <Route exact path={["/login", "/logout"]} render={(props) => 
              <Loginout authenticate={this.handleAuthenticate} route={props} />
            }/>
            <Route exact path="/my-list" render={(props) => this.handleMainSearchRoute(props)}/>
            <Route exact path="/" render={(props) => this.handleMainSearchRoute(props)} />
            <Route  component={NotFound} />
          </Switch>
          { 
            this.state.modalDisplayed 
              &&  
            <Modal
              showRemoveElement={this.handleShowRemoveElement} 
              modalDisplayed={this.state.modalDisplayed} 
              addRemoveMovie={this.handleAddRemoveMovie}
              movieList={this.state.userList.movies}
              selectedMovie={this.state.selectedMovie}
              isSignedIn={this.state.isSignedIn}
            /> 
          }
        </main>
        <Footer  />
      </React.Fragment>
    );
  }
}
 
export default withRouter(App);
