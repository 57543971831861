import React from 'react';
import { withRouter } from 'react-router-dom';
import './Footer.css';

const Footer = (props) => {
  const backgroundColor = props.location.pathname !== '/login' && props.location.pathname !== '/logout' ? 
  'Footer-black-background' : 'Footer-transparent-background';
  return ( 
    <footer className={`Footer ${backgroundColor}`}>
      <div className="Footer-content">
        @2019 made with <span role="img" aria-label="heart">❤️</span> and 
        <span role="img" aria-label="cry"> 😭</span> by <span className="Footer-author">Oliviel Valdez</span> 
      </div>
    </footer>
  );
}

export default withRouter(Footer);