import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  return ( 
    <section className="NotFound-container">
      <div className="NotFound-info">
        <h1 className="NotFound-title">
          Something went wrong
        </h1>
        <p className="NotFound-paragrah">
          Sorry, we couldn't find the page you were looking for:
          to return to the Netflix homepage click on the button bellow.
        </p>
        <Link to="/" style={{ textDecoration: 'none' }}> 
          <button className="NotFound-button">
            Go Home
          </button>
        </Link>
      </div>
      <div className="NotFound-tv-container">
        <div className="NotFound-tv-border">
          <div className="NotFound-tv-screen">
            <span className="NotFound-404">404</span>
          </div>
          <span className="NotFound-tv-on-button"></span>
        </div>
        <div className="NotFound-tv-stand"></div>
        <div className="NotFound-tv-stand-botton"></div>
      </div>
    </section>
  );
}
 
export default NotFound;