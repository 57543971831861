import React from 'react';
import { withRouter } from 'react-router-dom';
import Button from '../common/Button/button';
import Icon from '../common/Icon/icon';
import { imgBaseUrl } from '../../services/htttpServices';
import './Modal.css';

const Modal = (props) => {
  const { modalDisplayed } = props;
  const movieStyles = {
    background: `url(${imgBaseUrl}w1280${props.selectedMovie.backdrop_path})`,
    animation: 'top-buttom-translation 900ms ease-out',
  }
  const backupmovieStyles = {
    background: `url(https://www.freevector.com/uploads/vector/preview/2125/FreeVector-Rolls-Of-Film.jpg)`,
    animation: 'top-buttom-translation 900ms ease-out',
  }
  const selectedMovieStyles = props.selectedMovie.backdrop_path ? movieStyles : backupmovieStyles;

  const sameMovie = props.movieList.some(movie => movie.id === props.selectedMovie.id);

  const selectedIcon = sameMovie ? 'fa-times' : 'fa-plus';

  return ( 
    <div className="Modal-backdrop" onClick={() => props.showRemoveElement({ modalDisplayed })}>
      <div className="Modal-show" style={selectedMovieStyles} onClick={event => event.stopPropagation()}>
        <div className="Modal-container">
          <h1 className="Modal-title">
            {
              props.selectedMovie.original_title 
                || 
              props.selectedMovie.original_name
                ||
              'Not title found'
            }
          </h1>
          <p className="Modal-second-title">
            <span className="Modal-rating">
            Rating { 
              props.selectedMovie.vote_average ?
              props.selectedMovie.vote_average.toString().replace('.', '') 
                : 
              '0' 
            }% 
            </span> Release date: {
              props.selectedMovie.release_date 
                || 
              props.selectedMovie.first_air_date
                ||
              'No release data found'
            } 
          </p>
          <p className="Modal-paragrah">
            {props.selectedMovie.overview ? props.selectedMovie.overview.substring(0, 280) : 'No overview found.'}
          </p>
          <span>
            <Button 
              buttonStyleClass="btn btn-primary scale-animation mb-1 mr-1 mr-0-mobile" 
              iconstyleClass="fa fa-play mr-1" 
              label="play"
            />
          </span>
          <span onClick={() => {
            if (props.isSignedIn) props.addRemoveMovie(props.selectedMovie);
            else if (!props.isSignedIn) {
              props.showRemoveElement({ modalDisplayed });
              props.history.push('/login');
            } 
          }}>
            <Button 
              buttonStyleClass="btn btn-nobackground scale-animation" 
              iconstyleClass={`fa ${selectedIcon} mr-1`}
              label="My list"
            />
          </span>
        </div>
        <div onClick={() => props.showRemoveElement({ modalDisplayed })} className="Modal-close-container">
          <Icon iconstyleClass="fa fa-times fa-3x rotate-animation" />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Modal) ;