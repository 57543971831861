import React from 'react';
import './Loginout.css';

const Loginout = (props) => {
  function handleRedirection() {
    props.route.history.replace('/');
  }

  setTimeout(() => {
    handleRedirection();
  }, 30000);

  const image = {
    logIn: `${process.env.PUBLIC_URL}/assets/logincover.jpg`,
    logout: `${process.env.PUBLIC_URL}/assets/logoutcover.jpg`,
  }

  const backgroundImage = props.route.location.pathname === '/login' ? image.logIn : image.logout;

  const loginRoute = props.route.location.pathname === '/login';

  return ( 
    <React.Fragment>
        <img className="Loginout-backimage" src={backgroundImage} alt="net"/>
        {
          loginRoute
          &&
          <div className="Loginout-backdrop"></div>
        }
        <nav className="Loginout-navbar">
          <img 
            className="Loginout-logo" 
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/1280px-Netflix_2015_logo.svg.png" 
            alt="netlix logo" 
          />
        </nav>

        {
          loginRoute 
            ? 
          <section  className="Loginout-conteiner">
            <div className="Loginout-form-container">
              <p className="Loginout-title">Welcome back!</p>
              <button onClick={() => props.authenticate('Github')} className="Loginout-button mb-1">
                Log in with Github
              </button>
              <button onClick={() => props.authenticate('Twitter')} className="Loginout-button mb-1">
                Log in with Twitter
              </button>
              <button onClick={() => props.authenticate('Facebook')} className="Loginout-button mb-1">
                Log in with Facebook
              </button>
              <button onClick={() => props.authenticate('Google')} className="Loginout-button mb-1">
                Log in with Google
              </button>
            </div>
          </section> 
            :
          <section  className="Loginout-conteiner-logout">
            <h1 className="Loginout-title-logout">Signed out</h1>
            <p className="Loginout-paragraph-logout-1">
              Only members using a shared or public computer need to end each visit to Netflix by using the Sign Out link.
            </p>
            <p className="Loginout-paragraph-logout-2">
              This computer will be redirected to the Netflix home page in 30 seconds.
            </p>
            <div>
              <button onClick={handleRedirection} className="Loginout-button-logout">Continue</button>
            </div>
          </section> 
        }
    </React.Fragment>
  );
}
 
export default Loginout;

