import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Icon from '../common/Icon/icon';
import './Header.css';

const Header = (props) => {
  const { mobileNavDisplayed } = props;

  let headerClass = 'Header-container ';
  const background = props.isScrolled || mobileNavDisplayed  ? 'Header-dark-background' : 'Header-no-background';
  headerClass += background;

  let headerbakcdropClass = 'Header-backdrop ';
  const display = mobileNavDisplayed ? 'show-display' : 'hide-display';
  headerbakcdropClass += display;
  
  return ( 
    <React.Fragment>
      <header className={headerClass}>
        <div className="Header-main">
          <button onClick={() => props.showRemoveElement({ mobileNavDisplayed })} className="Header-toogle-button">
            <span className="Header-toggle-button-bar"></span>
            <span className="Header-toggle-button-bar"></span>
            <span className="Header-toggle-button-bar"></span>
          </button>
          <div>
            <img className="Header-logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/1280px-Netflix_2015_logo.svg.png" alt="netlix logo" />
          </div>
          <ul className="Header-list">
            <NavLink activeClassName="Header-items-active" exact to="/" style={{ textDecoration: 'none'}}>
              <li className="Header-items"> <span>Home</span> </li>
            </NavLink>
            <NavLink 
              activeClassName="Header-items-active" 
              exact 
              to={ props.isSignedIn ? "my-list" : "login" } 
              style={{ textDecoration: 'none'}}
            >
              <li className="Header-items"> <span>My List</span> </li>
            </NavLink>
          </ul>
        </div>
        <ul className="Header-list" id="Header-second-list"> 
          <li className="Header-items Header-list-icons">
            <div className="Header-search-container">
              <Icon iconstyleClass="fa fa-search mr-1" />
              <input 
                onChange={(e) => props.inputChange(e)}
                name="querySearch" value={props.querySearch} 
                className="Header-input-search" 
                type="text" 
                placeholder="Title, genres, people"/>
            </div>
          </li>
          <li className="Header-items Header-list-icons">
            <Icon iconstyleClass="fa fa-bell" />
          </li>
          <li className="Header-items Header-item-user-logo">
            <img className="Header-user-logo" src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/1bdc9a33850498.56ba69ac2ba5b.png" alt="user-logo" />
            <div className="Header-transparent-bar" ></div>
            {
              props.isSignedIn ? 
              <div className="Header-account-container">
                <div className="Header-account-user">
                  <img className="Header-user-logo" src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/1bdc9a33850498.56ba69ac2ba5b.png" alt="user-logo" />
                  <span className="Header-username">netflix addict</span>  
                </div>
                <div className="Header-account-container-2">
                  <span className="Header-signout" 
                  onClick={() =>{
                    props.logout();
                    props.history.push('/logout');
                  }}>Sign out of netflix</span>
                </div>
              </div>
                :
              null
            }
          </li>
        </ul>
      </header>
      <div className={headerbakcdropClass} onClick={() => props.showRemoveElement({ mobileNavDisplayed })}>
        <nav 
          className="Header-mobile-nav" 
          style={{ animation: 'left-center-translation 900ms ease-out' }} 
          onClick={event => event.stopPropagation()}
        >
        { props.isSignedIn 
            &&
          <ul className="Header-mobile-nav-items">
            <li className="Header-mobile-nav-item">
              <img className="Header-user-logo" src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/1bdc9a33850498.56ba69ac2ba5b.png" alt="user-logo" />
              <span className="Header-username">netflix addict</span>
            </li>
            <li className="Header-mobile-nav-item">
              <span  
                onClick={() =>{
                props.logout();
                props.history.push('/logout');
              }}>Sign out of Netflix</span>
            </li>
          </ul>
        }
          <hr className="Header-line-break" />
          <ul className="Header-mobile-nav-items">
            <NavLink exact to="/" style={{ textDecoration: 'none'}}>
              <li className="Header-mobile-nav-item"><span id="Header-item">Start</span></li>
            </NavLink>
            <NavLink 
              exact 
              to={ props.isSignedIn ? "my-list" : "login" } 
              style={{ textDecoration: 'none'}}
            >
              <li className="Header-mobile-nav-item">
                <span id="Header-item">My list</span>
              </li>
            </NavLink>
            {props.moviesGenres.length > 0 &&
              props.moviesGenres.map(genre => (
              <li key={genre.id} className="Header-mobile-nav-item">
                <span>{genre.name}</span>
              </li> 
            ))}
          </ul>
        </nav>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Header);