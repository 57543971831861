import React from 'react';
import { imgBaseUrl } from '../../services/htttpServices';
import './Carousel.css';


const Carousel = (props) => {
  const backupImage = 'http://chilihouse.com/wp-content/uploads/2018/07/picture-not-available.jpg';
  const { modalDisplayed } = props;
  return ( 
    <section className="Carousel-container">
      <h1 className="Carousel-title">{ props.title }</h1>
      <div className="Carousel-movies-container">
        {props.movies.map(movie => (
          <div 
            key={movie.id} 
            style={props.imgSize} 
            onClick={() => {
              props.selectedMovieModal(movie);
              props.showRemoveElement({ modalDisplayed });
            }} 
            className={`Carousel-movie 
              ${props.title === 'Trending Now' ? 
              'Carousel-movie-main' : 
              'Carousel-movie-secondary' }`}
          >
          <img 
            className="Carousel-movie-image" 
            src={movie.poster_path ? `${imgBaseUrl}w780${movie.poster_path}` : backupImage} 
            alt= {movie.original_title || movie.original_name}
          />
          </div>
        ))}
      </div>
    </section>
  );
}
 
export default Carousel;
