import React from 'react';
import { imgBaseUrl } from '../../services/htttpServices';
import './MovieSearch.css';

const MovieSearch = (props) => {
  const { modalDisplayed } = props;
  const backupImage = 'http://chilihouse.com/wp-content/uploads/2018/07/picture-not-available.jpg';
  return ( 
    <section className="MovieSearch-container">
       <div className="MovieSearch-movies-container">
         {props.movies.map(movie => (
            <div 
              key={movie.id} 
              style={props.imgSize} 
              className="MovieSearch-movie"
              onClick={() => {
                props.selectedMovieModal(movie);
                props.showRemoveElement({ modalDisplayed });
              }}
            >
            <img 
              className="MovieSearch-movie-image" 
              src={movie.poster_path ? `${imgBaseUrl}w780${movie.poster_path}` : backupImage} 
              alt= {movie.original_title || movie.original_name}
            />
          </div>
         ))}
      </div>
    </section>
  );
}
 
export default MovieSearch;