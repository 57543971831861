import axios from 'axios';

const baseUrl = 'https://api.themoviedb.org/3/';
const imgBaseUrl = 'https://image.tmdb.org/t/p/';
const apiKey = 'api_key=cb37c27b0330adaa48dfced3b4d522b6';

axios.interceptors.response.use(null, error => {
  const expectedError = 
    error.response 
      && 
    error.response.status >= 400 
      && 
    error.response.status < 500; 

  if (!expectedError) {
    console.log('loggin the error', error);
  } 

  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
}

export {
  baseUrl,
  apiKey,
  imgBaseUrl,
}