import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database'

const config = {
  apiKey: "AIzaSyAGQpSNB7c9d4M1WVJoxpNqpsBMtRqFHF8",
  authDomain: "mini-flix.firebaseapp.com",
  databaseURL: "https://mini-flix.firebaseio.com",
};

firebase.initializeApp(config);

// var database = firebase.database();

const firebaseApp = firebase;

export default config;
export { firebaseApp }


