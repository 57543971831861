import { firebaseApp } from '../base';
import http, { baseUrl, apiKey } from '../services/htttpServices';

export const apiRequest = async (state) => {
  
  const { data: movieGenres } = await http.get(`${baseUrl}genre/movie/list?${apiKey}`);
  const { data: movieHomeCover } = await http.get(`${baseUrl}search/tv?${apiKey}&query=breaking+bad&page=1`);
  const { data: topTrending } = await http.get(`${baseUrl}trending/all/week?${apiKey}`);
  const { data: topRated } = await http.get(`${baseUrl}movie/top_rated?${apiKey}`);
  const { data: action } = await http.get(`${baseUrl}discover/movie?${apiKey}&with_genres=28`);
  const { data: comedy } = await http.get(`${baseUrl}discover/movie?${apiKey}&with_genres=35`);
  const { data: mistery } = await http.get(`${baseUrl}discover/movie?${apiKey}&with_genres=9648`);
  const { data: romance } = await http.get(`${baseUrl}discover/movie?${apiKey}&with_genres=10749`);
  const { data: animation } = await http.get(`${baseUrl}discover/movie?${apiKey}&with_genres=16`);
  const { data: documentary } = await http.get(`${baseUrl}discover/movie?${apiKey}&with_genres=99`);

  state.movieHomeCover = movieHomeCover.results[0];
  state.moviesGenres = movieGenres.genres;
  state.movies.topTrending = topTrending.results;
  state.movies.topRated = topRated.results;
  state.movies.actionGenre = action.results;
  state.movies.comedyGenre = comedy.results;
  state.movies.misteryGenre = mistery.results;
  state.movies.romanceGenre = romance.results;
  state.movies.animationGenre = animation.results;
  state.movies.documentaryGenre = documentary.results;

  return state;
}

export const queryRequest = async (query) => { 
  const { data } = await http.get(`${baseUrl}search/multi?${apiKey}&language=en-US&page=1&query=${query}`);
  return data.results;
}

export const getFirebaseData = async (oldUserList, userId) => {
  const eventref = firebaseApp.database().ref('/');
  const snapshot = await eventref.once('value');
  const value = snapshot.val();

  let keys;

  if (value) {
    keys = Object.keys(value);
  }

  let userList;

  if (keys) {
    for (const key of keys) {
      if (value[key].owner === userId) {
        userList = value[key];
        return userList;
      }
    }
  }

  userList = { ...oldUserList };

  userList.owner = userId;

  return userList;
}


