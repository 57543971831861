import React from 'react';
import "./icon.css";
import 'font-awesome/css/font-awesome.css';

const Icon = ({ iconstyleClass }) => {
  return <i className={iconstyleClass}></i>;
}
 
export default Icon;

