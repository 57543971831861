import React from 'react';
import Carousel from '../Carousel/Carousel';
import Button from '../common/Button/button';
import { imgBaseUrl } from '../../services/htttpServices';
import './Home.css';

const Home = (props) => {
  const movieStyles = {
    backgroundImage: `url(${imgBaseUrl}w1280${props.movieHomeCover.backdrop_path})`,
  }

  const sameMovie = props.movieList.some(movie => movie.id === props.movieHomeCover.id);

  const selectedIcon = sameMovie ? 'fa-times' : 'fa-plus';

  return ( 
    <React.Fragment>
      <section className="Home-main-section" style={movieStyles}>
        <div className="Home-main-content">
          <h1 className="Home-main-title">{props.movieHomeCover.name}</h1>
          <Button 
            buttonStyleClass="btn btn-secondary mb-1 mr-1 mr-0-mobile" 
            iconstyleClass="fa fa-play mr-1" 
            label="Play"
          />
          <span 
          onClick={() => 
          { props.isSignedIn ? 
            props.addRemoveMovie(props.movieHomeCover)
              :
            props.router.history.push('/login')
          }}>
            <Button 
              buttonStyleClass="btn btn-secondary" 
              iconstyleClass={`fa ${selectedIcon} mr-1`}
              label="My List"
            />
          </span>
          <p className="Home-main-decription">
          { props.movieHomeCover && props.movieHomeCover.overview.substring(0, 140) }
          </p>
        </div>
      </section>
      <Carousel  
        title="Trending Now" 
        imgSize={{ width: '25rem', height: '40rem' }} 
        showRemoveElement={props.showRemoveElement}
        selectedMovieModal={props.selectedMovieModal}
        modalDisplayed={props.modalDisplayed}
        movies={props.movies.topTrending}
      />
      <Carousel 
        title="Top Rated" 
        imgSize={{ width: '30rem', height: '20rem' }} 
        showRemoveElement={props.showRemoveElement}
        selectedMovieModal={props.selectedMovieModal}
        modalDisplayed={props.modalDisplayed}
        movies={props.movies.topRated}
      /> 
      <Carousel 
        title="Action Movies" 
        imgSize={{ width: '30rem', height: '20rem' }} 
        showRemoveElement={props.showRemoveElement}
        selectedMovieModal={props.selectedMovieModal}
        modalDisplayed={props.modalDisplayed}
        movies={props.movies.actionGenre}
      />
      <Carousel 
        title="Comedy Movies" 
        imgSize={{ width: '30rem', height: '20rem' }} 
        showRemoveElement={props.showRemoveElement}
        selectedMovieModal={props.selectedMovieModal}
        modalDisplayed={props.modalDisplayed}
        movies={props.movies.comedyGenre}
      /> 
      <Carousel 
        title="Mistery Movies" 
        imgSize={{ width: '30rem', height: '20rem' }} 
        showRemoveElement={props.showRemoveElement}
        selectedMovieModal={props.selectedMovieModal}
        modalDisplayed={props.modalDisplayed}
        movies={props.movies.misteryGenre}
      /> 
      <Carousel 
        title="Romance Movies" 
        imgSize={{ width: '30rem', height: '20rem' }} 
        showRemoveElement={props.showRemoveElement}
        selectedMovieModal={props.selectedMovieModal}
        modalDisplayed={props.modalDisplayed}
        movies={props.movies.romanceGenre}
      /> 
      <Carousel 
        title="Animated Movies" 
        imgSize={{ width: '30rem', height: '20rem' }} 
        showRemoveElement={props.showRemoveElement}
        selectedMovieModal={props.selectedMovieModal}
        modalDisplayed={props.modalDisplayed}
        movies={props.movies.animationGenre}
      /> 
       <Carousel 
        title="Documentaries" 
        imgSize={{ width: '30rem', height: '20rem' }} 
        showRemoveElement={props.showRemoveElement}
        selectedMovieModal={props.selectedMovieModal}
        modalDisplayed={props.modalDisplayed}
        movies={props.movies.documentaryGenre}
      /> 
    </React.Fragment>
  );
}
 
export default Home;