import React from 'react';
import './button.css';
import Icon from '../Icon/icon'

const Button = ({ label, buttonStyleClass, iconstyleClass}) => {
  return (
    <button 
      className={buttonStyleClass}> 
      { iconstyleClass && <Icon iconstyleClass={iconstyleClass}/> } 
      {label}
    </button>
  );
}
 
export default Button;

